import { gql, useQuery } from "@apollo/client";
import { css } from "@emotion/react";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { useEffect } from "react";
import LoadableImage from "./LoadableImage";
import QRCode from "./QRCode";

const styles = {
  loadableImage: css`
    background-color: inherit;
  `,
};

const QUERY = gql`
  query GetQRCodeImage($id: uuid!) {
    qr_code_by_pk(id: $id) {
      code
      public_url
    }
  }
`;

export interface QRCodeSharingProps {
  id: string;
  size?: number;
  onPrint?: () => void;
}

export default function QRCodeSharing(p: QRCodeSharingProps) {
  const { data, loading, startPolling, stopPolling } = useQuery(QUERY, {
    variables: { id: p.id },
    skip: !p.id,
  });
  const size = p.size || 150;
  const imageUrl = data?.qr_code_by_pk?.public_url;
  const shareUrl = data?.qr_code_by_pk?.code
    ? `givaudanvisa.com/q/${data.qr_code_by_pk.code}`
    : null;
  const sizeCss = css`
    text-align: center;
    width: ${size}px;
    height: ${size}px;
  `;

  useEffect(() => {
    if (imageUrl) {
      stopPolling();
    } else {
      startPolling(1000);
    }
  }, [imageUrl, startPolling, stopPolling]);

  const displayMessage = (message: string) => {
    console.log(message);
  };

  const handleCopyUrlClick = () => {
    navigator.clipboard.writeText(shareUrl || "").then(
      () => {
        displayMessage("Copied!");
      },
      (err) => {
        console.error("Async: Could not copy text: ", err);
        displayMessage("Failed to copy");
      }
    );
  };

  if (loading) {
    // return <Skeleton variant="rect" css={sizeCss} className={p.className}/>
  }

  if (!imageUrl) {
    // return <CircularProgress css={sizeCss} className={p.className} size={p.size} color="secondary" />
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <TextField
        label="Shareable Link"
        contentEditable={false}
        value={shareUrl}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleCopyUrlClick}>
                <FontAwesomeIcon icon={faCopy} fixedWidth size="sm" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <LoadableImage
        css={[sizeCss, styles.loadableImage]}
        url={imageUrl}
        {...p}
      />
      {p.onPrint ? (
        <Button onClick={p.onPrint}>Print label sheet</Button>
      ) : null}
    </Box>
  );
}
