import React from "react";
import { css } from "@emotion/react";
import { gql, useQuery } from "@apollo/client";
import Typography from "../../components/Typography";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";

const cssDescriptorParent = css`
  display: grid;
  grid-template-columns: 125px 1fr;
  grid-template-rows: minmax(75px, max-content);
  gap: 0px 0px;
  grid-template-areas: ". .";
  border-top: 2px solid white;
`;

const cssTextArea = css`
  padding: 10px;
  width: 100vw;
  box-sizing: border-box;
`;

interface SampleProps {
  name: string;
  description: string;
  onClick?: () => void;
}

export const Sample = (p: SampleProps) => {
  return (
    <div css={cssDescriptorParent} onClick={p.onClick}>
      <div css={cssTextArea}>
        <Typography center role="subheading">{p.name}</Typography>
        <Typography center role="label"><span style={{whiteSpace: "pre-wrap"}}>{p.description}</span></Typography>
      </div>
    </div>
  );
};

const cssParent = css``;

interface SamplesProps {
  sessionId: string;
}

interface QueryData {
  project_by_pk?: {
    image: string;
    description: string;
    name: string;
    samples: [
      {
        id: string;
        image: string;
        name: string;
        description: string;
      }
    ]
  }
}

interface QueryVars {
  sessionId: string;
}

const QUERY = gql`
query MyQuery($sessionId: uuid!) {
  project_by_pk(id: $sessionId) {
    image
    description
    name
    samples(order_by: {name: asc}, where: {is_hidden: {_eq: false}}) {
      id
      image
      name
      description
    }
  }
}
`;

export default function Samples(p: SamplesProps) {
  const { loading, error, data } = useQuery<QueryData, QueryVars>(QUERY, {
    variables: { sessionId: p.sessionId },
  });
  const history = useHistory();

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error...</div>;
  }
  if (!data?.project_by_pk) {
    return <div>No data...</div>;
  }

  return (
    <div css={cssParent}>
      <Box padding={2}>
      <Typography center role='heading'>{data.project_by_pk.name}</Typography>
      <Typography center role='paragraph'><span style={{whiteSpace: "pre-wrap"}}>{data.project_by_pk.description}</span></Typography>
      </Box>
      {data.project_by_pk.samples.map((sample) => {
        return (
          <Sample
            key={sample.id}
            name={sample.name}
            description={sample.description}
            onClick={() => history.push(`/app/project/${p.sessionId}/samples/${sample.id}`)}
          />
        );
      })}
    </div>
  );
};
