import React, { useState } from "react";
import { css } from "@emotion/react";
import {
  TextField,
  Button,
  CardContent,
  Card,
  Typography,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
  FormControlLabel,
  Box,
  InputLabel,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  buildUpdateLexiconVariables,
  GetLexiconById,
  GetLexiconByIdResponse,
  GetLexiconByIdVariables,
  ListDescriptors,
  ListDescriptorsResponse,
  UpdateLexicon,
  UpdateLexiconVariables,
} from "./queries";
import LoadableImage from "../components/LoadableImage";
import ImageUpload from "../components/ImageUpload";
import QRCode from "../components/QRCode";
import Footer from "../components/Footer";
import DeleteLexicon from "./DeleteLexicon";
import { PrintLabelsButton } from "./PrintLabelQrCodes";
import LexiconList from "../../../pages/shared/lexicon/LexiconList";
import QRCodeSharing from "../components/QRCodeSharing";

const CREATE_NEW_LEXICON_MUTATION = gql`
  mutation CreateNewLexicon(
    $name: String!
    $image: String!
    $description: String!
    $descriptors: [lexicon_descriptor_insert_input!]!
  ) {
    insert_lexicon_one(
      object: {
        lexicon_descriptors: { data: $descriptors }
        image: $image
        description: $description
        name: $name
        qr_codes: { data: {} }
      }
    ) {
      id
      qr_codes {
        id
      }
    }
  }
`;

interface DescriptorSelectorProps {
  selected: string[];
  onSelectionChange: (newSelection: string[]) => void;
}

const DescriptorSelector = (p: DescriptorSelectorProps) => {
  const { data } = useQuery<ListDescriptorsResponse, {}>(ListDescriptors);

  if (!data) {
    return null;
  }

  return (
    <TableContainer
      component={Paper}
      css={css`
        height: 100%;
      `}
    >
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell>Descriptor</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          css={css`
            max-height: 400px;
            overflow: scroll;
          `}
        >
          {data.descriptor.map((d) => (
            <TableRow
              key={d.id}
              onClick={() => {
                if (p.selected.includes(d.id)) {
                  p.onSelectionChange(p.selected.filter((id) => id !== d.id));
                } else {
                  p.onSelectionChange([...p.selected, d.id]);
                }
              }}
            >
              <TableCell>
                <FormControlLabel
                  checked={p.selected.includes(d.id)}
                  control={<Checkbox name="checkedB" color="primary" />}
                  label={d.name}
                />
              </TableCell>
              <TableCell>{d.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface LexiconPreviewProps {
  name: string;
  description: string;
  descriptorIds: string[];
}

const LexiconPreview = (p: LexiconPreviewProps) => {
  const { data, loading } = useQuery<ListDescriptorsResponse, {}>(
    ListDescriptors
  );

  if (loading) {
    return <div>loading</div>;
  }

  const descriptors =
    data?.descriptor.filter((d) => {
      return p.descriptorIds.includes(d.id);
    }) || [];

  return (
    <div>
      <LexiconList
        name={p.name.trim().length ? p.name : "Lexicon Name"}
        description={
          p.description.trim().length ? p.description : "Lorem ipsum dolor"
        }
        descriptors={descriptors}
      />
    </div>
  );
};

const styles = {
  container: css`
    height: calc(100vh - 160px);
    display: grid;
    grid-template-columns: 1fr 400px;
    grid-template-rows: 265px 1fr auto;
    gap: 1em 1em;
    grid-template-areas:
      "main preview"
      "descriptors preview"
      "footer footer";
  `,
  main: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 1em 1em;
    grid-template-areas: "name image qr";
    grid-area: main;
  `,
};

interface EditLexiconLoadedProps {
  id: string;
  name: string;
  image: string;
  description: string;
  selectedIds: string[];
  qrCodes: string[];
}

const EditLexiconLoaded = (p: EditLexiconLoadedProps) => {
  const isNew = p.id === "new" || !p.id;
  const history = useHistory();
  const [name, setName] = useState(p.name);
  const [description, setDescription] = useState(p.description);
  const [image, setImage] = useState(p.image);
  const [selectedIds, setSelectedIds] = useState(p.selectedIds);
  const [createLexicon] = useMutation(CREATE_NEW_LEXICON_MUTATION, {
    refetchQueries: ["GetLexiconById", "ListLexicons"],
  });
  const [updateLexicon] = useMutation<{}, UpdateLexiconVariables>(
    UpdateLexicon,
    { refetchQueries: ["GetLexiconById", "ListLexicons"] }
  );

  const handleCreateLexiconClicked = () => {
    if (isNew) {
      // Editing exiting lexicon
      createLexicon({
        variables: {
          name: name,
          image: image,
          description: description,
          descriptors: selectedIds.map((id) => {
            return { descriptor_id: id };
          }),
        },
      }).then((resp) => {
        history.replace(`/admin/lexicons`);
      });
    } else {
      updateLexicon({
        variables: buildUpdateLexiconVariables(
          p.id,
          name,
          description,
          image,
          selectedIds
        ),
      }).then((resp) => {
        history.replace(`/admin/lexicons`);
      });
    }
  };

  return (
    <div css={styles.container}>
      <Card
        css={css`
          grid-area: main;
        `}
      >
        <CardContent css={styles.main}>
          <div
            css={css`
              grid-area: name;
            `}
          >
            <Typography gutterBottom variant="h5" component="h2">
              {isNew ? "Create a Lexicon" : "Update Lexicon"}
            </Typography>
            <TextField
              fullWidth
              label="Lexicon name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div
              css={css`
                width: 100%;
                height: 10px;
              `}
            />
            <TextField
              fullWidth
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={4}
            />
          </div>
          <div
            css={css`
              grid-area: image;
            `}
          >
            <InputLabel>Image</InputLabel>
            <LoadableImage
              url={image}
              css={css`
                height: 125px;
              `}
            />
            <ImageUpload onSuccess={setImage} />
          </div>
          {isNew ? null : (
            <div
              css={css`
                grid-area: qr;
              `}
            >
              <QRCodeSharing
                id={p.qrCodes[0]}
              />
              <PrintLabelsButton lexiconId={p.id} />
            </div>
          )}
        </CardContent>
      </Card>
      <Card
        css={css`
          grid-area: descriptors;
        `}
      >
        <DescriptorSelector
          selected={selectedIds}
          onSelectionChange={setSelectedIds}
        />
      </Card>
      <Card
        css={css`
          grid-area: preview;
        `}
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Preview
          </Typography>
          <div
            css={css`
              margin: auto;
              background-color: #111111;
              height: 650px;
              width: 350px;
              overflow: auto;
            `}
          >
            <LexiconPreview
              name={name}
              description={description}
              descriptorIds={selectedIds}
            />
          </div>
        </CardContent>
      </Card>
      <Footer
        css={css`
          grid-area: footer;
        `}
      >
        <div>
          {isNew ? null : (
            <Box component="span" m={1}>
              <DeleteLexicon lexiconId={p.id} />
            </Box>
          )}
          <Box component="span" m={1}>
            <Button
              variant="contained"
              onClick={() => {
                history.push(`/admin/lexicons`);
              }}
            >
              Cancel
            </Button>
          </Box>
          <Box component="span">
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateLexiconClicked}
            >
              {isNew ? "Create Lexicon" : "Save Changes"}
            </Button>
          </Box>
        </div>
      </Footer>
    </div>
  );
};

interface EditLexiconProps {
  id?: string;
}

const EditLexicon = (p: EditLexiconProps) => {
  const isNew = !p.id || p.id === "new";
  const { data } = useQuery<GetLexiconByIdResponse, GetLexiconByIdVariables>(
    GetLexiconById,
    { variables: { id: p.id! }, skip: isNew }
  );

  if (isNew) {
    return (
      <EditLexiconLoaded
        id="new"
        name="New Lexicon"
        description="Description for a new lexicon"
        image=""
        selectedIds={[]}
        qrCodes={[]}
      />
    );
  } else if (data) {
    return (
      <EditLexiconLoaded
        id={data.lexicon_by_pk.id}
        name={data.lexicon_by_pk.name}
        description={data.lexicon_by_pk.description}
        image={data.lexicon_by_pk.image}
        selectedIds={data.lexicon_by_pk.descriptors.map((d) => d.id)}
        qrCodes={data.lexicon_by_pk.qr_codes.map((qr) => qr.id)}
      />
    );
  } else {
    return (
      <div css={styles.container}>
        <Skeleton
          css={css`
            grid-area: main;
            background-color: red;
            height: unset !important;
          `}
          variant="rect"
        />
        <Skeleton
          css={css`
            grid-area: preview;
            background-color: red;
            height: unset !important;
          `}
          variant="rect"
        />
        <Skeleton
          css={css`
            grid-area: descriptors;
            background-color: red;
            height: unset !important;
          `}
          variant="rect"
        />
      </div>
    );
  }
};

export default EditLexicon;
