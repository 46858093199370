import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const query = gql`
query ListSurveys {
  SUR_survey {
    id
    internal_name
  }
}
`;

export interface ListSurveysData {
  SUR_survey: {
    id: string;
    internal_name: string;
  }[];
}

export function useListSurveys(
  options?: QueryHookOptions<ListSurveysData, {}> | undefined
) {
  return useQuery<ListSurveysData, {}>(
    query,
    options
  );
}
