import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const MUTATION = gql`
mutation CloneSurveyQuestion($question_id: uuid!) {
  clone_SUR_survey_question(questionId: $question_id) {
    success
  }
}
`;

export interface CloneQuestionData {
  delete_SUR_survey_question_by_pk: {
    id: string;
    survey_id: string;
    options: {
      id: string;
    }[]
  }
}

export interface CloneQuestionVariables {
  question_id: string;
}

export function useCloneQuestionMutation(
  options?:
    | MutationHookOptions<CloneQuestionData, CloneQuestionVariables>
    | undefined
) {
  return useMutation<CloneQuestionData, CloneQuestionVariables>(
    MUTATION,
    options
  );
}
