import { gql, useQuery } from "@apollo/client";
import { MenuItem, Select } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useListSurveys } from "../../../queries/ListSurveys";

const NO_SURVEY = "NO_SURVEY"

export interface SurveySelectProps {
  selectedSurveyId: string | null;
  onChange: (newSurveyId: string | null) => void;
}

export function SurveySelect(p: SurveySelectProps) {
  const { data, loading } = useListSurveys();

  const effectiveId = p.selectedSurveyId || NO_SURVEY;

  const handleChange = (newId: string) => {
    if (newId === NO_SURVEY) {
      p.onChange(null);
    } else {
      p.onChange(newId);
    }
  }

  if (loading) {
    return <Skeleton variant="rect" />;
  } else if (data) {
    return (
      <Select
        fullWidth
        value={effectiveId}
        onChange={(e) => handleChange(e.target.value as string)}
      >
        <MenuItem key={NO_SURVEY} value={NO_SURVEY}>
          <i>No survey</i>
        </MenuItem>
        {data.SUR_survey.map((sur) => (
          <MenuItem key={sur.id} value={sur.id}>
            {sur.internal_name}
          </MenuItem>
        ))}
      </Select>
    );
  } else {
    return <span>Error loading</span>;
  }
}
