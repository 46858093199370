import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const RESPONDENT_SURVEY_DETAILS_QUERY = gql`
query RespondentSurveyDetails($survey_id: uuid!) {
  SUR_survey_by_pk(id: $survey_id) {
    id
    name
    description
    media_url
    questions(order_by: {sort_position: asc}) {
      id
      type
      name
      description
      required
      min_value
      max_value
      answer
      parent
      logic_value
      validation_mode
      validation_count
      validation_error_message
      options(order_by: {sort_position: asc}) {
        id
        text
      }
    }
    attachments {
      id
      label
      url
    }
  }
}
`;

export interface RespondentSurveyDetailsData {
  SUR_survey_by_pk: {
    id: string;
    name: string;
    description: string;
    media_url: string | null;
    questions: {
      id: string;
      type: string;
      name: string;
      description: string | null;
      required: boolean;
      min_value: number | null;
      max_value: number | null;
      answer: string | null;
      parent: string | null;
      logic_value: string | null;
      validation_mode: string | null;
      validation_count: number | null;
      validation_error_message: string | null;
      options: {
        id: string;
        text: string;
      }[];
    }[];
    attachments: {
      id: string;
      label: string;
      url: string;
    }[];
  }
}

export interface RespondentSurveyDetailsVariables {
  survey_id: string;
}

export function useRespondentSurveyDetailsQuery(
  options?:
    | QueryHookOptions<
        RespondentSurveyDetailsData,
        RespondentSurveyDetailsVariables
      >
    | undefined
) {
  return useQuery<
    RespondentSurveyDetailsData,
    RespondentSurveyDetailsVariables
  >(RESPONDENT_SURVEY_DETAILS_QUERY, options);
}
