import { css } from "@emotion/react";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  Button,
  CardContent,
  MobileStepper,
  Typography,
  styled,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { QuestionCardProps } from "../admin/pages/surveys/components/QuestionCard";
import SurveyCard from "../components/SurveyCard";
import {
  getSelectionCount,
  isMultiSelectType,
} from "./components/inputs/util/selectionUtilities";
import QuestionCard, {
  QuestionCardConstantProps,
} from "./components/QuestionCard";
import SwipeableViews from "react-swipeable-views";
import { LoadableMedia } from "../admin/pages/components/LoadableMedia";

function getErrorMessage(
  p: QuestionCardConstantProps,
  value: string | null
): string | null {
  if (p.required && value == null) {
    return "This question is required.";
  }
  if (
    isMultiSelectType(p.type) &&
    p.validationMode != null &&
    p.validationCount != null &&
    value != null
  ) {
    const selectionCount = getSelectionCount(value);
    switch (p.validationMode) {
      case "at_least":
        if (selectionCount < p.validationCount) {
          return (
            p.validationErrorMessage ||
            `You must make at least ${p.validationCount} selections.`
          );
        }
        break;
      case "at_most":
        if (selectionCount > p.validationCount) {
          return (
            p.validationErrorMessage ||
            `You must make at most ${p.validationCount} selections.`
          );
        }
        break;
      case "exactly":
        if (selectionCount !== p.validationCount) {
          return (
            p.validationErrorMessage ||
            `You must make exactly ${p.validationCount} selections.`
          );
        }
        break;
    }
  }

  return null;
}

function getQuestionDescendents(
  parentId: string,
  questions: QuestionCardConstantProps[]
): QuestionCardConstantProps[] {
  const qs = [...questions]; // Copy input array so we can mutate;
  let descendents = [] as QuestionCardConstantProps[];
  let shouldSearch = true;

  while (shouldSearch) {
    shouldSearch = false;

    questions = questions.filter((q) => {
      if (!q.parent) {
        return false;
      }

      if (q.parent === parentId || descendents.find((d) => d.id === q.parent)) {
        descendents.push(q);
        shouldSearch = true;
        return false;
      }

      return true;
    });
  }
  return descendents;
}

export interface Answers {
  [questionId: string]: string | null;
}

export interface SurveyFormDataProps {
  name: string;
  description: string;
  mediaUrl: string | null;
  questions: QuestionCardConstantProps[];
  attachments: {
    id: string;
    label: string;
    url: string;
  }[];
}

export interface SurveyFormProps extends SurveyFormDataProps {
  onSubmit: (answers: Answers) => void;
  onError: () => void;
}

export default function SurveyForm(p: SurveyFormProps) {
  const [answers, setAnswers] = useState<Answers>({});
  const [wasSubmittedWithErrors, setWasSubmittedWithErrors] =
    useState<boolean>(false);
  const [correct, setCorret] = useState<string[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [len, setLength] = useState<number>(0);
  const [page, setPage] = React.useState(0); // Page 0 corresponds to the header card
  const hasAnyRequiredFields = p.questions.find((q) => q.required) != null;
  const hasAnyAttachments = Boolean(p.attachments) && p.attachments.length > 0;
  const handleValueChanged = (questionId: string, value: string | null) => {
    const newAnswers = { ...answers };
    newAnswers[questionId] = value;
    setAnswers(newAnswers);
  };

  // Questions which always appear
  const rootQuestions = p.questions.filter((q) => !q.parent);

  const isOnLastPage = page === rootQuestions.length;

  const handleSubmit = () => {
    // Loop through each question, check if it has any errors.
    console.log("handling survey submit...");
    for (let q of p.questions) {
      if (getErrorMessage(q, answers[q.id]) != null) {
        console.log(
          `error on question ${q.name}: ${getErrorMessage(q, answers[q.id])}`
        );
        console.log(`${JSON.stringify(q)}`);
        setWasSubmittedWithErrors(true);
        p.onError();

        let newActiveStep = rootQuestions.findIndex((candidate) =>
          isDescendent(candidate.id, q.id)
        );
        if (newActiveStep === -1) {
          newActiveStep = 0;
        }
        setPage(newActiveStep);

        return;
      }
    }

    console.log("calling p.onSubmit");
    p.onSubmit(answers);
  };

  useEffect(() => {
    let tempTotal = 0;
    let tempLen = 0;
    p.questions.map((q, index) => {
      if (
        q.answer !== "" &&
        q.answer !== undefined &&
        (q.parent === "" || q.parent === null)
      ) {
        tempTotal++;
      }
      if (q.parent === "" || q.parent === null) {
        tempLen++;
      }
    });
    setLength(tempLen);
    console.log(tempLen);
    setTotal(tempTotal);
  }, []);

  function isDescendent(parentId: string, childId: string): boolean {
    const childQuestion = p.questions.find((q) => q.id === childId);
    if (!childQuestion) {
      return false;
    } else if (childQuestion.parent === parentId) {
      return true;
    } else {
      return isActiveDescendent(parentId, childQuestion.parent);
    }
  }

  function isActiveDescendent(parentId: string, childId: string): boolean {
    const childQuestion = p.questions.find((q) => q.id === childId);
    if (!childQuestion) {
      return false;
    } else if (childQuestion.parent === parentId) {
      const answer = answers[childQuestion.parent];
      const requiredAnswer = childQuestion.logic_value;
      return answer === requiredAnswer;
    } else {
      const answer = answers[childQuestion.parent];
      const requiredAnswer = childQuestion.logic_value;
      return (
        isActiveDescendent(parentId, childQuestion.parent) &&
        answer === requiredAnswer
      );
    }
  }

  function eval_questions(q: QuestionCardConstantProps, value: String) {
    if (q.type === "select_radio" && q.answer !== null) {
      if (answers[q.id] !== undefined && q.answer !== "") {
        if (value === q.answer) {
          if (!correct.includes(String(q.id))) {
            if (q.parent === "" || q.parent === null) {
              correct.push(q.id);
            }
          }
        }
      }
    }
  }
  function eval_quesiton(q: QuestionCardConstantProps, value: String): string {
    if (q.type === "select_radio" && q.answer !== null) {
      if (answers[q.id] !== undefined && q.answer !== "") {
        if (value === q.answer) {
          return "";
        } else {
          return "Try Again";
        }
      }
    }
    return "";
  }

  const handleNext = (totalQuestions: number) => {
    if (page === totalQuestions) {
      handleSubmit();
    }
    setPage((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setPage((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setPage(step);
  };

  const BlueButton = styled(Button)({
    background: "#00265f",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  });

  return (
    <div
      css={css`
        width: 100%;
        max-width: 500px;
      `}
    >
      {/* Swipable area where questions are displayed */}
      <SwipeableViews
        css={css`max-height: calc(100vh - 200px); overflow-y: auto; overflow-x: hidden;`}
        index={page}
        onChangeIndex={handleStepChange}
      >
        {/* Header card */}
        <SurveyCard topStripe>
          <CardContent>
            <Typography variant="h4">{p.name}</Typography>
            <Typography variant="body1">{p.description}</Typography>
            {p.mediaUrl ? <LoadableMedia url={p.mediaUrl} /> : null}

            {hasAnyRequiredFields ? (
              <Typography
                css={css`
                  color: #ff3333;
                `}
              >
                * Required
              </Typography>
            ) : null}

            {hasAnyAttachments ? (
              <Box>
                <Typography>Attachments</Typography>
                {p.attachments.map((attachment) => (
                  <Box>
                    <a href={attachment.url} target="_blank">
                      {attachment.label}
                    </a>
                  </Box>
                ))}
              </Box>
            ) : null}
          </CardContent>
        </SurveyCard>

        {rootQuestions.map((questionProps) => {
          const value = answers[questionProps.id];
          const errorMessage = wasSubmittedWithErrors
            ? getErrorMessage(questionProps, value)
            : null; // Only show error nags if user has tried to submit
          eval_questions(questionProps, String(value));
          let status = eval_quesiton(questionProps, String(value));

          return (
            <div>
              <Box key={questionProps.id} marginTop={1} width="100%">
                <QuestionCard
                  value={value}
                  errorMessage={errorMessage}
                  status={status}
                  onValueChanged={(newValue) =>
                    handleValueChanged(questionProps.id, newValue)
                  }
                  {...questionProps}
                />

                {p.questions.map((potentialDescendentProps) => {
                  if (
                    isActiveDescendent(
                      questionProps.id,
                      potentialDescendentProps.id
                    )
                  ) {
                    const value = answers[potentialDescendentProps.id];
                    const errorMessage = wasSubmittedWithErrors
                      ? getErrorMessage(potentialDescendentProps, value)
                      : null; // Only show error nags if user has tried to submit
                    eval_questions(potentialDescendentProps, String(value));
                    let status = eval_quesiton(
                      potentialDescendentProps,
                      String(value)
                    );
                    return (
                      <QuestionCard
                        value={value}
                        errorMessage={errorMessage}
                        status={status}
                        onValueChanged={(newValue) =>
                          handleValueChanged(
                            potentialDescendentProps.id,
                            newValue
                          )
                        }
                        {...potentialDescendentProps}
                      />
                    );
                  }

                  return null;
                })}
              </Box>
            </div>
          );
        })}
      </SwipeableViews>

      {/* Score  */}
      {len <= Object.keys(answers).length ? (
        <Box>
          <h3>
            Score: {correct.length}/{total}
          </h3>
        </Box>
      ) : null}

      {/* Controls to navigate between questions */}
      <MobileStepper
        style={{ marginTop: "20px" }}
        activeStep={page}
        steps={rootQuestions.length + 1}
        position="static"
        backButton={
          <Button
            color="inherit"
            disabled={page === 0}
            onClick={handleBack}
            // css={{ mr: 1 }}
          >
            Back
          </Button>
        }
        nextButton={
          <Button
            onClick={() => handleNext(rootQuestions.length)}
            disabled={page === rootQuestions.length}
          >
            Next
          </Button>
        }
      />

      {/* Submit button */}
      {isOnLastPage ? (
        <Box marginTop={1}>
          <BlueButton
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit Response
          </BlueButton>
        </Box>
      ) : null}
    </div>
  );
}
