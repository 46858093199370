import React, { useState } from "react";
import { css } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import Footer from "../components/Footer";
import {
  GetProjectNameByIdResponse,
  GetProjectNameByIdVariables,
  GetProjectNameById,
  CreateSample,
  CreateSampleResponse,
  CreateSampleVariables,
  GetSampleDetails,
  GetSampleDetailsResponse,
  GetSampleDetailsVariables,
  UpdateSampleResponse,
  UpdateSampleVariables,
  UpdateSample,
} from "../projects/queries";
import QRCode from "../components/QRCode";
import DeleteSample from "../projects/DeleteSample";

const styles = {
  container: css`
    height: calc(100vh - 160px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    gap: 1em 1em;
    grid-template-areas:
      "main"
      "samples"
      "footer";
  `,
  main: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 1em 1em;
  `,
  descriptorRatingGroup: css``,
  descriptorRatingGroupName: css`
    display: inline-block;
    width: 80px;
    height: 20px;
    margin: 2px;
    padding: 5px;
  `,
  descriptorRatingBox: css`
    display: inline-block;
    height: 20px;
    margin: 2px;
    padding: 5px;
    border: 1px solid black;
  `,
};

interface EditSampleLoadedProps {
  id: string;
  projectId: string;
  name: string;
  description: string;
  isHidden: boolean;
  qrCode: string[];
}

const EditSampleLoaded = (p: EditSampleLoadedProps) => {
  const history = useHistory();
  const { data } = useQuery<
    GetProjectNameByIdResponse,
    GetProjectNameByIdVariables
  >(GetProjectNameById, {
    variables: { id: p.projectId },
    skip: !p.projectId,
  });
  const [createSample] = useMutation<
    CreateSampleResponse,
    CreateSampleVariables
  >(CreateSample, { refetchQueries: ["GetProjectById"] });
  const [updateSample] = useMutation<
    UpdateSampleResponse,
    UpdateSampleVariables
  >(UpdateSample, { refetchQueries: ["GetProjectById"] });

  const [name, setName] = useState(p.name);
  const [description, setDescription] = useState(p.description);
  const [isHidden, setIsHidden] = useState(p.isHidden);
  const isNew = !p.id || p.id === "new";

  const handleCancelClicked = () => {
    history.replace(`/admin/sessions/${p.projectId}`);
  };
  const handleCreateSampleClicked = () => {
    createSample({
      variables: {
        project_id: p.projectId,
        name: name,
        description: description,
      },
    }).then(() => {
      history.push(`/admin/sessions/${p.projectId}`);
    });
  };
  const handleSaveChangesClicked = () => {
    updateSample({
      variables: {
        id: p.id,
        name: name,
        description: description,
        isHidden: isHidden
      },
    }).then(() => {
      history.push(`/admin/sessions/${p.projectId}`);
    });
  };

  return (
    <div css={styles.container}>
      <Card>
        <CardContent css={styles.main}>
          <div
            css={css`
              max-width: 400px;
            `}
          >
            <TextField
              fullWidth
              label="Sample name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Box marginTop={1} marginBottom={2}>
              <Typography variant="subtitle2">
                Part of project <i>{data?.project_by_pk?.name || "..."}</i>
              </Typography>
            </Box>
            <TextField
              fullWidth
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={4}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isHidden}
                  onChange={() => {
                    setIsHidden(ih => !ih);
                  }}
                  name="checkedA"
                />
              }
              label="Hide from list when session QR code is scanned"
            />
          </div>
          <div>
            <InputLabel>QR Code</InputLabel>
            <QRCode
              css={css`
                width: 200px;
                height: 200px;
              `}
              id={p.qrCode}
              fallbackText={isNew ? "Generated after creation" : undefined}
            />
          </div>
        </CardContent>
      </Card>
      <Footer>
        <div>
          {isNew ? null : (
            <Box component="span" m={1}>
              <DeleteSample projectId={p.projectId} sampleId={p.id} />
            </Box>
          )}
          <Box component="span" m={1}>
            <Button variant="contained" onClick={handleCancelClicked}>
              Cancel
            </Button>
          </Box>
          <Box component="span">
            {isNew ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateSampleClicked}
              >
                Create Sample
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveChangesClicked}
              >
                Save Changes
              </Button>
            )}
          </Box>
        </div>
      </Footer>
    </div>
  );
};

interface EditSampleProps {
  id: string;
  projectId: string;
}

const EditSample = (p: EditSampleProps) => {
  const isNew = !p.id || p.id === "new";
  const { data } = useQuery<
    GetSampleDetailsResponse,
    GetSampleDetailsVariables
  >(GetSampleDetails, {
    variables: { id: p.id },
  });

  if (isNew) {
    return (
      <EditSampleLoaded
        id={p.id}
        projectId={p.projectId}
        name="New sample"
        description=""
        isHidden={false}
        qrCode={[]}
      />
    );
  }
  if (data?.sample_by_pk) {
    return (
      <EditSampleLoaded
        id={p.id}
        projectId={p.projectId}
        name={data.sample_by_pk.name}
        description={data.sample_by_pk.description}
        isHidden={data.sample_by_pk.is_hidden}
        qrCode={data.sample_by_pk.qr_codes?.map((q) => q.id) || []}
      />
    );
  } else {
    return <span>Loading</span>;
  }
};

export default EditSample;
