import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
  Typography,
  withStyles
} from "@material-ui/core";
import React from "react";
import QInputProps from "./QInputProps";
import { packValue, unpackValue } from "./util/selectionUtilities";

export default function QSelectCheckbox(p: QInputProps) {
  if (p.options == null || p.options.length === 0) {
    return <Typography>No options available.</Typography>;
  }

  const value = unpackValue(p.value, p.options);

  const handleChange = (index: number, checked: boolean) => {
    const newValue = [...value];
    newValue[index] = checked;
    p.onValueChange(packValue(newValue, p.options))
  }

  const BlueCheckbox = withStyles({
    root: {
      color: "#4b94f2",
      '&$checked': {
        color: "#4b94f2",
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

  return (
    <FormGroup>
      {value.map((checked, index) => (
        <FormControlLabel
          key={p.options![index]}
          control={
            <BlueCheckbox checked={checked} onChange={(e, checked) => handleChange(index, checked)} name={p.options![index]} />
          }
          label={p.options![index]}
        />
      ))}
    </FormGroup>
  );
}
