import { Box } from "@material-ui/core";
import React from "react";
import LoadableImage from "./LoadableImage";

export interface LoadableMediaProps {
  url: string;
}

export function LoadableMedia(p: LoadableMediaProps) {
  const isVideo = p.url.endsWith(".mp4");

  return isVideo ? (
    <Box>
      <video width="100%" controls>
        <source src={p.url} />
      </video>
    </Box>
  ) : (
    <Box>
      <LoadableImage url={p.url} />
    </Box>
  );
}
