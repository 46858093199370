import React, { useState } from "react";
import { css } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import LoadableImage from "../components/LoadableImage";
import { useHistory } from "react-router-dom";
import QRCode from "../components/QRCode";
import ImageUpload from "../components/ImageUpload";
import Footer from "../components/Footer";
import {
  CreateProject,
  CreateProjectResponse,
  CreateProjectVariables,
  GetProjectById,
  GetProjectByIdResponse,
  GetProjectByIdVariables,
  UpdateProjectInfo,
  UpdateProjectInfoResponse,
  UpdateProjectInfoVariables,
} from "./queries";
import { Skeleton } from "@material-ui/lab";
import {
  ListLexiconNames,
  ListLexiconNamesResponse,
} from "../lexicons/queries";
import DeleteProject from "./DeleteProject";
import { PrintLabelsButton } from "./ProjectQrCodePrinter";
import { SurveySelect } from "./components/SurveySelect";
import { ExportProjectCsvButton } from "./components/ExportProjectCsvButton";
import QRCodeSharing from "../components/QRCodeSharing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

interface LexiconSelectProps {
  id?: string;
  onSelect: (id: string) => void;
}

const LexiconSelect = (p: LexiconSelectProps) => {
  const { data, loading } =
    useQuery<ListLexiconNamesResponse>(ListLexiconNames);
  if (loading) {
    return <Skeleton variant="rect" />;
  } else if (data) {
    return (
      <Select
        fullWidth
        value={p.id}
        onChange={(e) => p.onSelect(e.target.value as string)}
      >
        {data.lexicon.map((lex) => (
          <MenuItem key={lex.id} value={lex.id}>
            {lex.name}
          </MenuItem>
        ))}
      </Select>
    );
  } else {
    return <span>Error loading</span>;
  }
};

interface SampleTableProps {
  projectId: string;
  samples: {
    id: string;
    name: string;
    description: string;
    isHidden: boolean;
    qrCodes: string[];
  }[];
}

const SampleTable = (p: SampleTableProps) => {
  const history = useHistory();

  const handleCreateNewClicked = () => {
    history.push(`/admin/sessions/${p.projectId}/samples/new`);
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>
              Visibility{" "}
              <Tooltip title="Controls whether this sample appears in the list when the session QR code is scanned. Hidden samples can still be viewed if their specific QR code is scanned directly.">
                <span>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell>QR Code</TableCell>
            <TableCell>
              <Box display="flex" justifyContent="flex-end">
                <ExportProjectCsvButton projectId={p.projectId} />
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleCreateNewClicked}
                >
                  Create New
                </Button>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {p.samples.map((sam) => (
            <TableRow
              key={sam.id}
              onDoubleClick={() =>
                history.push(`/admin/sessions/${p.projectId}/samples/${sam.id}`)
              }
            >
              <TableCell>{sam.name}</TableCell>
              <TableCell>{sam.description}</TableCell>
              <TableCell style={{ width: "75px" }}>
                {sam.isHidden ? (
                  <span>
                    <FontAwesomeIcon icon={faEyeSlash} /> Hidden
                  </span>
                ) : (
                  <span>
                    <FontAwesomeIcon icon={faEye} /> Visible
                  </span>
                )}
              </TableCell>
              <TableCell>
                <QRCode id={sam.qrCodes} size={75} />
              </TableCell>
              <TableCell>
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={() =>
                    history.push(
                      `/admin/sessions/${p.projectId}/samples/${sam.id}`
                    )
                  }
                >
                  Manage
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const styles = {
  container: css`
    height: calc(100vh - 160px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 265px 1fr auto;
    gap: 1em 1em;
    grid-template-areas:
      "main"
      "samples"
      "footer";
  `,
  main: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 1em 1em;
    grid-template-areas: "name image lexicon qr";
    grid-area: main;
  `,
};

interface EditProjectLoadedProps {
  id: string;
  name: string;
  description: string;
  image: string;
  lexicon_id?: string;
  survey_id: string | null;
  qrCodes: string[];
  enableQuestionLiking: boolean;
  enableQuestionConceptFit: boolean;
  samples: {
    id: string;
    name: string;
    description: string;
    isHidden: boolean;
    qrCodes: string[];
  }[];
}

const EditProjectLoaded = (p: EditProjectLoadedProps) => {
  const history = useHistory();
  const isNew = p.id === "new";
  const [name, setName] = useState(p.name);
  const [description, setDescription] = useState(p.description);
  const [image, setImage] = useState(p.image);
  const [lexiconId, setLexiconId] = useState(p.lexicon_id);
  const [surveyId, setSurveyId] = useState(p.survey_id);
  const [enableQuestionLiking, setEnableQuestionLiking] = useState(
    p.enableQuestionLiking
  );
  const [enableQuestionConceptFit, setEnableQuestionConceptFit] = useState(
    p.enableQuestionConceptFit
  );

  const [createProject] = useMutation<
    CreateProjectResponse,
    CreateProjectVariables
  >(CreateProject, { refetchQueries: ["GetProjects"] });
  const [updateProject] = useMutation<
    UpdateProjectInfoResponse,
    UpdateProjectInfoVariables
  >(UpdateProjectInfo, { refetchQueries: ["GetProjects"] });

  const handleCreateProjectClicked = () => {
    if (isNew) {
      // Editing exiting project
      createProject({
        variables: {
          name: name,
          image: image,
          description: description,
          lexicon_id: lexiconId || undefined,
          survey_id: surveyId,
        },
      }).then((resp) => {
        history.replace(`/admin/sessions`);
      });
    } else {
      updateProject({
        variables: {
          id: p.id,
          name: name,
          image: image,
          description: description,
          lexicon_id: lexiconId || undefined,
          survey_id: surveyId,
          enable_question_rate_sample: enableQuestionLiking,
          enable_question_concept_fit: enableQuestionConceptFit,
        },
      }).then((resp) => {
        history.replace(`/admin/sessions`);
      });
    }
  };

  return (
    <div css={styles.container}>
      <Card
        css={css`
          grid-area: main;
        `}
      >
        <CardContent css={styles.main}>
          <div>
            <Typography align="center" variant="h5">
              Project
            </Typography>
            <TextField
              fullWidth
              label="Session name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              fullWidth
              rows={4}
              multiline
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div>
            <InputLabel>Image</InputLabel>
            <LoadableImage
              url={image}
              css={css`
                height: 125px;
              `}
            />
            <ImageUpload onSuccess={setImage} />
          </div>
          <div>
            <Box>
              <InputLabel>Lexicon</InputLabel>
              <LexiconSelect id={lexiconId} onSelect={setLexiconId} />
            </Box>
            <Box marginTop={1}>
              <InputLabel>Survey</InputLabel>
              <SurveySelect
                selectedSurveyId={surveyId}
                onChange={setSurveyId}
              />
            </Box>
            <Box marginTop={3}>
              <Typography>Rating options</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={enableQuestionLiking}
                    onChange={(e) => setEnableQuestionLiking(e.target.checked)}
                  />
                }
                label="Rate sample liking"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={enableQuestionConceptFit}
                    onChange={(e) =>
                      setEnableQuestionConceptFit(e.target.checked)
                    }
                  />
                }
                label="Rate concept fit"
              />
            </Box>
          </div>
          {isNew ? null : (
            <Box>
              <QRCodeSharing id={p.qrCodes[0]} />
              <PrintLabelsButton projectId={p.id} />
            </Box>
          )}
        </CardContent>
      </Card>
      {isNew ? (
        <Paper
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <Typography>
            Samples can be added after a project is created
          </Typography>
        </Paper>
      ) : (
        <SampleTable samples={p.samples} projectId={p.id} />
      )}
      <Footer>
        <div>
          {isNew ? null : (
            <Box component="span" m={1}>
              <DeleteProject projectId={p.id} />
            </Box>
          )}
          <Box component="span" m={1}>
            <Button
              variant="contained"
              onClick={() => {
                history.push(`/admin/sessions`);
              }}
            >
              Cancel
            </Button>
          </Box>
          <Box component="span">
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateProjectClicked}
            >
              {isNew ? "Create Session" : "Save Changes"}
            </Button>
          </Box>
        </div>
      </Footer>
    </div>
  );
};

interface EditProjectProps {
  id: string;
}

const EditProject = (p: EditProjectProps) => {
  const isNew = !p.id || p.id === "new";
  const { data } = useQuery<GetProjectByIdResponse, GetProjectByIdVariables>(
    GetProjectById,
    { variables: { id: p.id! }, skip: isNew }
  );

  if (isNew) {
    return (
      <EditProjectLoaded
        id="new"
        name="New Project"
        description="Description for a new project"
        image=""
        lexicon_id=""
        survey_id={null}
        qrCodes={[]}
        samples={[]}
        enableQuestionLiking={true}
        enableQuestionConceptFit={true}
      />
    );
  } else if (data) {
    return (
      <EditProjectLoaded
        id={data.project_by_pk.id}
        name={data.project_by_pk.name}
        description={data.project_by_pk.description}
        image={data.project_by_pk.image}
        lexicon_id={data.project_by_pk.lexicon_id}
        survey_id={data.project_by_pk.survey_id}
        qrCodes={data.project_by_pk.qr_codes.map((code) => code.id)}
        samples={data.project_by_pk.samples.map((s) => ({
          ...s,
          isHidden: s.is_hidden,
          qrCodes: s.qr_codes.map((c) => c.id),
        }))}
        enableQuestionLiking={data.project_by_pk.enable_question_rate_sample}
        enableQuestionConceptFit={
          data.project_by_pk.enable_question_concept_fit
        }
      />
    );
  } else {
    return (
      <div css={styles.container}>
        <Skeleton
          css={css`
            grid-area: main;
            background-color: red;
            height: unset !important;
          `}
          variant="rect"
        />
        <Skeleton
          css={css`
            grid-area: preview;
            background-color: red;
            height: unset !important;
          `}
          variant="rect"
        />
        <Skeleton
          css={css`
            grid-area: descriptors;
            background-color: red;
            height: unset !important;
          `}
          variant="rect"
        />
      </div>
    );
  }
};

export default EditProject;
