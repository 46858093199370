import { gql, useLazyQuery } from "@apollo/client";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import triggerDownload from "js-file-download";
import React, { useState } from "react";

const query = gql`
query data_export($id: uuid!) {
  data_export_csv_single(projectId: $id) {
    csv
  }
  project_by_pk(id: $id) {
    name
  }
}

`;

function useCsvQuery() {
  return useLazyQuery<
    {
      data_export_csv_single: {
        csv: string;
      } | null;
      project_by_pk: {
        name: string;
      } | null;
    },
    {
      id: string;
    }
  >(query);
}

export interface ExportProjectCsvButtonProperties {
  projectId: string;
}

export function ExportProjectCsvButton(
  p: ExportProjectCsvButtonProperties
) {
  const [busy, setBusy] = useState(false);
  const [query, a] = useCsvQuery();

  const handleClick = () => {
    setBusy(true);
    query({ variables: { id: p.projectId } }).then(result => {
      const csvData = result.data?.data_export_csv_single?.csv;
      const name = result.data?.project_by_pk?.name;
      if (csvData != null && name != null) {
        triggerDownload(csvData, `project_feedback_${name}.csv`, 'text/csv')
      } else {
        console.log('got no data when calling query for downloading project csv')
      }
    });
    console.log("handle download project csv");
  };

  return (
    <IconButton color="primary" onClick={handleClick}>
      <FontAwesomeIcon icon={faDownload} />
    </IconButton>
  );
}
