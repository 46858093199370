import { gql, QueryHookOptions, useQuery } from "@apollo/client";

const query = gql`
query ProjectSurveyId($id: uuid!) {
  project_by_pk(id: $id) {
    survey_id
  }
}
`;

interface ProjectSurveyIdVariables {
  id: string;
}

interface ProjectSurveyIdData {
  project_by_pk: {
    survey_id: string | null;
  };
}

export default function useProjectSurveyId(
  options?: QueryHookOptions<ProjectSurveyIdData, ProjectSurveyIdVariables>
) {
  return useQuery<ProjectSurveyIdData, ProjectSurveyIdVariables>(query, {
    ...options,
  });
}
