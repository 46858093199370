import React, { useState } from "react";
import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import { css } from "@emotion/react";
import { useHistory } from "react-router-dom";
import { useUpdateUserActivation } from "../../queries/UpdateUserActivation";

const styles = {
  backdrop: css`
    z-index: 999,
    color: '#f00',
  `,
};

interface UserActivatorDeactivatorProps {
  mode: "activate" | "deactivate";
  userName: string;
  userId: string;
}

export default function UserActivatorDeactivator(p: UserActivatorDeactivatorProps) {
  const history = useHistory();
  const disabling = p.mode === "deactivate";
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [updateUserActivation] = useUpdateUserActivation();

  const handleDeleteClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    updateUserActivation({
      variables: {
        id: p.userId,
        disabled: disabling,
      },
    }).then(handleClose).catch(e => {
      console.log('Error altering user activatin status:');
      console.log(e);
    });
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color={disabling ? "secondary" : "primary"}
        onClick={handleDeleteClick}
        disabled={open}
      >
        {disabling ? "Deactivate" : "Activate"}
      </Button>
      <Backdrop css={styles.backdrop} style={{ zIndex: 999 }} open={open}>
        <Card
          css={css`
            max-width: 300px;
          `}
        >
          <CardContent>
            <Typography variant="h6">
              {disabling ? "Deactivate account" : "Activate account"}
            </Typography>
            <Typography variant="body2">
              {disabling
                ? `This account will be deactivated and it will no longer be possible to log in using its provided secret. Any content created by this account will remain. You may reactivate the account later if you choose.`
                : `This account will be activated once again, allowing log ins using its provided secret.`}
            </Typography>
            <br/>
            <Typography variant="body2">
              <b>Account name: </b>{p.userName}
            </Typography>
            {error ? (
              <Typography variant="body2">
                Error altering user: {error}
              </Typography>
            ) : null}
          </CardContent>
          <CardActions>
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleConfirm}
            >
              {disabling ? "Deactivate account" : "Activate account"}
            </Button>
          </CardActions>
        </Card>
      </Backdrop>
    </React.Fragment>
  );
}
