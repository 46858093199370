import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, InputAdornment, TableCell, TextField } from "@material-ui/core";
import {
  faFilter
} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export interface FilterableTableHeaderProps {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
}

export function FilterableTableHeader(p: FilterableTableHeaderProps) {
  return <TableCell>
    <Box display="flex" flexDirection="column">
      {p.label}
      <TextField value={p.value} onChange={e => p.onChange(e.target.value)}
               InputProps={{
                startAdornment: <InputAdornment position="start"><FontAwesomeIcon icon={faFilter}/></InputAdornment>,
              }}/>
    </Box>
  </TableCell>
}