import { css } from "@emotion/react";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import React from "react";
import QInputProps from "./QInputProps";
import _ from "lodash";

const styles = {
  numberStyle: css`
    font-size: 20px;
    line-height: 40px;
    padding-left: 10px;
    padding-right: 10px;
  `,
  iconStyle: css`
    // padding-left: 2px;
    // padding-right: 2px;
  `,
};

function getIconSize(iconCount: number): SizeProp {
  if (iconCount > 15) {
    return "xs";
  }
  if (iconCount > 10) {
    return "sm";
  }
  if (iconCount > 5) {
    return "1x";
  }
  return "2x";
}

export interface QRatingGenericProps extends QInputProps {
  icon: IconProp;
  color: string;
}

export default function QRatingGeneric(p: QRatingGenericProps) {
  const numericValue = p.value == null ? 0 : parseInt(p.value) || 0;
  const min = p.minValue == null ? 1 : p.minValue;
  const max = p.maxValue == null ? 5 : p.maxValue;
  const stars = max - min + 1;
  const iconSize = getIconSize(stars);

  const styleEmpty = css`
    cursor: pointer;
  `;
  const styleFull = css`
    cursor: pointer;
    color: ${p.color};
  `;

  return (
    <Box display="flex" flexDirection="row" justifyContent="center" padding={1}>
      <div css={styles.numberStyle}>{min}</div>
      {_.range(stars).map((idx) => (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          onClick={() => p.onValueChange(`${idx + 1}`)}
          css={numericValue > idx ? styleFull : styleEmpty}
        >
          <FontAwesomeIcon
            icon={p.icon}
            size={iconSize}
            css={styles.iconStyle}
            fixedWidth
          />
        </Box>
      ))}
      <div css={styles.numberStyle}>{max}</div>
    </Box>
  );
}
