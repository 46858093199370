import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const UPDATE_SURVEY_INFO = gql`
mutation UpdateSurveyInfo($survey_id: uuid!, $internal_name: String!, $name: String!, $description: String, $media_url: String) {
  update_SUR_survey_by_pk(pk_columns: {id: $survey_id}, _set: {internal_name: $internal_name, name: $name, description: $description, media_url: $media_url}) {
    id
    internal_name
    name
    description
    media_url
  }
}
`;

export interface UpdateSurveyInfoData {
  update_SUR_survey_question_option_by_pk: {
    id: string;
    internal_name: string;
    name: string;
    description: string | null;
    media_url: string | null;
  }
}

export interface UpdateSurveyInfoVariables {
  survey_id: string;
  internal_name: string;
  name: string;
  description?: string | null;
  media_url: string | null;
}

export function useUpdateSurveyInfoMutation(
  options?:
    | MutationHookOptions<UpdateSurveyInfoData, UpdateSurveyInfoVariables>
    | undefined
) {
  return useMutation<UpdateSurveyInfoData, UpdateSurveyInfoVariables>(
    UPDATE_SURVEY_INFO,
    options
  );
}
