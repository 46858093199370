import React from "react";
import { Box, Typography } from "@material-ui/core";
import Descriptor from "./Descriptor";
import { css } from "@emotion/react";

const styles = {
  container: css`
    background-color: black;
    color: white;
  `,
};

interface LexiconProps {
  lexicon: {
    id: string;
    name: string;
    description: string;
    image: string;
    descriptors: {
      id: string;
      name: string;
      description: string;
      image: string;
    }[];
  };
  onDescriptorSelected: (descriptorId: string) => void;
}

const Lexicon = (p: LexiconProps) => {
  return (
    <div css={styles.container}>
      <Box padding="5px">
        <Typography align="center" variant="h5">
          {p.lexicon.name}
        </Typography>
        <Typography align="center" variant="body1">
          <span style={{ whiteSpace: "pre-wrap" }}>
            {p.lexicon.description}
          </span>
        </Typography>
      </Box>
      {p.lexicon.descriptors.map((d) => {
        return (
          <Descriptor
            key={d.id}
            descriptor={d}
            onClick={() => p.onDescriptorSelected(d.id)}
          />
        );
      })}
    </div>
  );
};

export default Lexicon;
