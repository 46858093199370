import React, { useState } from "react";
import { css } from "@emotion/react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
} from "@material-ui/core";
import { gql, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import LoadableImage from "../components/LoadableImage";
import QRCode from "../components/QRCode";
import { FilterableTableHeader } from "../components/FilterableTableHeader";

const QUERY = gql`
  query GetProjects {
    project {
      id
      name
      image
      description
      samples_aggregate {
        aggregate {
          count
        }
      }
      qr_codes {
        id
      }
    }
  }
`;

interface QueryData {
  project: [
    {
      id: string;
      name: string;
      image?: string;
      description?: string;
      samples_aggregate: {
        aggregate: {
          count: number;
        };
      };
      qr_codes: {
        id: string;
      }[];
    }
  ];
}

const ProjectList = () => {
  const history = useHistory();
  const { data } = useQuery<QueryData>(QUERY, { pollInterval: 2000 });
  const [filterString, setFilterString] = useState("");
  const filteredProjectList = (data?.project || []).filter((p) =>
    p.name.toLowerCase().includes(filterString.toLowerCase())
  );

  const handleCreateNewClicked = () => {
    history.push(`/admin/sessions/new`);
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <FilterableTableHeader
              label="Session"
              value={filterString}
              onChange={setFilterString}
            />
            <TableCell>Image</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Samples</TableCell>
            <TableCell>QR Code</TableCell>
            <TableCell
              css={css`
                min-width: 100px;
              `}
            >
              <Button
                css={css`
                  width: 100px;
                `}
                size="small"
                variant="contained"
                color="primary"
                onClick={handleCreateNewClicked}
              >
                Create
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredProjectList.map((proj) => {
            return (
              <TableRow
                key={proj.id}
                onDoubleClick={() => history.push(`/admin/sessions/${proj.id}`)}
              >
                <TableCell component="th" scope="row">
                  {proj.name}
                </TableCell>
                <TableCell>
                  <LoadableImage variant="icon" url={proj.image} />
                </TableCell>
                <TableCell>{proj.description}</TableCell>
                <TableCell>{proj.samples_aggregate.aggregate.count}</TableCell>
                <TableCell>
                  <QRCode id={proj.qr_codes.map((q) => q.id)} size={75} />
                </TableCell>
                <TableCell>
                  <Button
                    css={css`
                      width: 100px;
                    `}
                    variant="contained"
                    size="small"
                    onClick={() =>
                      history.replace(`/admin/sessions/${proj.id}`)
                    }
                  >
                    Manage
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectList;
