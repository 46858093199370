import { css } from "@emotion/react";
import {
  Box,
  Button,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import SurveyCard from "../../../../components/SurveyCard";
import { useDebouncedEffect } from "../../../../util/useDebouncedEffect";
import { useIsMount } from "../../../../util/useIsMount";
import FileUpload from "../../components/FileUpload";
import ImageUpload from "../../components/ImageUpload";
import LoadableImage from "../../components/LoadableImage";
import { LoadableMedia } from "../../components/LoadableMedia";
import SurveyQRCodePrinter from "./SurveyQRCodePrinter";

export interface InformationCardProps {
  qrCodes: { id: string; code: string }[];
  surveyId: string;
  internalName: string;
  name: string;
  description: string;
  mediaUrl: string | null;
  attachments: { id: string; label: string; url: string }[];
  onUpdate: (
    internalName: string,
    name: string,
    description: string,
    mediaUrl: string | null
  ) => void;
  onAttachmentAdded: (label: string, url: string) => void;
  onAttachmentRemoved: (id: string) => void;
}

export default function InformationCard(p: InformationCardProps) {
  const isFirstRender = useIsMount();
  const [internalName, setInternalName] = useState(p.internalName);
  const [name, setName] = useState(p.name);
  const [description, setDescription] = useState(p.description);
  const [mediaUrl, setMediaUrl] = useState(p.mediaUrl);

  const handleFileUpload = (fileName: string, url: string) => {
    p.onAttachmentAdded(fileName, url);
  };

  useDebouncedEffect(
    () => {
      if (isFirstRender) {
        return;
      }
      p.onUpdate(internalName, name, description, mediaUrl);
    },
    100,
    [internalName, name, description, mediaUrl]
  );

  return (
    <SurveyCard topStripe>
      <CardContent>
        <Box display="flex" flexDirection="row">
          <Box flexGrow={1} marginRight={2}>
            <TextField
              fullWidth
              label="Internal Name"
              value={internalName}
              onChange={(e) => setInternalName(e.target.value)}
            />
            <TextField
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Box marginTop={1}>
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
            <Box marginTop={2}>
              <Typography>Media</Typography>
              <Typography variant="caption">
                Images (.jpg, .png, .gif) and videos (.mp4) are supported.
              </Typography>
              {mediaUrl && (
                <Box>
                  <LoadableMedia url={mediaUrl} />
                  <Button onClick={() => setMediaUrl(null)}>Delete</Button>
                </Box>
              )}
              <ImageUpload allowVideo onSuccess={setMediaUrl} />
            </Box>
            <Box marginTop={2}>
              <Typography>Attachments</Typography>
              {p.attachments.map((attachment) => (
                <Box key={attachment.id}>
                  <Button onClick={() => p.onAttachmentRemoved(attachment.id)}>
                    Delete
                  </Button>
                  <a href={attachment.url} target="_blank">
                    {attachment.label}
                  </a>
                </Box>
              ))}
              <FileUpload onSuccess={handleFileUpload} />
            </Box>

            {/* <video width="100%" controls>
              <source src="http://res.cloudinary.com/est-analytical/video/upload/v1654812509/uw6penscbjifqbgvcwrc.mp4" />
            </video> */}
          </Box>
          <SurveyQRCodePrinter
            surveyId={p.surveyId}
            surveyName={p.name}
            qrCodes={p.qrCodes}
          />
        </Box>
      </CardContent>
    </SurveyCard>
  );
}
