import { Box } from "@material-ui/core";
import React from "react";
import SurveyPage from "../../../../survey/SurveyPage";
import BottomTabs from "../../layout/BottomTabs";
import Layout from "../../layout/Layout";
import ProjectOverview from "../../project/ProjectInfo";
import useProjectSurveyId from "../../queries/ProjectSurveyId";

export interface ProjectSurveyPageProps {
  projectId: string;
}

export function ProjectSurveyPage(p: ProjectSurveyPageProps) {
  const { data, loading } = useProjectSurveyId({variables: {id: p.projectId}});
  return (
    <Layout
      overview={<ProjectOverview projectId={p.projectId} />}
      footer={<BottomTabs projectId={p.projectId} />}
    >
      {loading ? (
        <Box style={{color: "white"}}>Loading...</Box>
      ) : null}
      {data?.project_by_pk?.survey_id ? (
        <SurveyPage surveyId={data.project_by_pk.survey_id} />
      ) : (
        <Box style={{color: "white"}}>This session does not include a survey.</Box>
      )}
    </Layout>
  );
}
