import React from "react";
import { css } from "@emotion/react";
import DescriptorGrid from "./DescriptorGrid";
import { Box, Typography } from "@material-ui/core";
import SampleRatings from "./SampleRatings";

const styles = {
  container: css`
    background-color: black;
  `,
  header: css`
    color: white;
    padding: 10px;
    margin-bottom: 10px;
  `,
};

interface SampleDetailsProps {
  sample: {
    id: string;
    name: string;
    description: string;
    descriptors: {
      id: string;
      name: string;
      rating: number;
    }[];
    likingScore?: number;
    conceptFitScore?: number;
    comments?: string;
  };
  enableQuestionLiking: boolean;
  enableQuestionConceptFit: boolean;
  onRatingChange: (
    descriptorId: string,
    newRating: number,
    currentRating: number
  ) => void;
  onFeedbackSaved: (
    likingScore?: number,
    conceptFitScore?: number,
    comments?: string
  ) => Promise<void>;
}

export default function SampleDetails(p: SampleDetailsProps) {
  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <Typography align="center" variant="h6">
          {p.sample.name}
        </Typography>
        <Typography align="center" variant="body2">
          <span style={{ whiteSpace: "pre-wrap" }}>{p.sample.description}</span>
        </Typography>
      </div>
      <DescriptorGrid
        descriptors={p.sample.descriptors}
        onRatingChange={p.onRatingChange}
      />
      <Box marginTop="10px" marginBottom="20px">
        <Typography
          align="center"
          css={css`
            color: white;
          `}
          variant="h6"
        >
          Key
        </Typography>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 5px 5px;
            padding: 5px;
            margin-top: 10px;
          `}
        >
          <div
            css={css`
              background-color: rgb(125, 125, 125);
              display: grid;
              justify-content: center;
              align-items: center;
              padding: 5px;
              text-align: center;
            `}
          >
            Not present
          </div>
          <div
            css={css`
              background-color: rgb(241, 247, 214);
              display: grid;
              justify-content: center;
              align-items: center;
              padding: 5px;
              text-align: center;
            `}
          >
            Too <b>weak</b>
          </div>
          <div
            css={css`
              background-color: rgb(180, 212, 71);
              display: grid;
              justify-content: center;
              align-items: center;
              padding: 5px;
              text-align: center;
            `}
          >
            Just <b>right</b>
          </div>
          <div
            css={css`
              background-color: rgb(91, 128, 41);
              display: grid;
              justify-content: center;
              align-items: center;
              padding: 5px;
              text-align: center;
            `}
          >
            Too <b>strong</b>
          </div>
        </div>
      </Box>
      <SampleRatings
        enableQuestionConceptFitScore={p.enableQuestionConceptFit}
        enableQuestionLikingScore={p.enableQuestionLiking}
        likingScore={p.sample.likingScore}
        conceptFitScore={p.sample.conceptFitScore}
        comments={p.sample.comments}
        onFeedbackSaved={p.onFeedbackSaved}
      />
      <div
        css={css`
          height: 20vh;
          width: 100%;
        `}
      />
    </div>
  );
}
