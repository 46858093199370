import React, { useState } from "react";
import { css } from "@emotion/react";
import Footer from "../../components/Footer";
import DescriptorFeedback, {
  DescriptorFeedbackFallback,
} from "./DescriptorFeedback";
import {
  Box,
  Button,
  Card,
  CardContent,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import ImageUpload from "../../components/ImageUpload";
import LoadableImage from "../../components/LoadableImage";
import DescriptorColor from "../../components/DescriptorColor";
import QRCode from "../../components/QRCode";
import DeleteDescriptor from "./DeleteDescriptor";

const styles = {
  container: css`
    height: calc(100vh - 160px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 250px 1fr auto;
    gap: 1em 1em;
    grid-template-areas:
      "main"
      "feedback"
      "footer";
  `,
  main: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 1em 1em;
    grid-area: main;
  `,
};

export interface Descriptor {
  id: string;
  name: string;
  description: string;
  image: string;
  color?: string;
  colorName?: string;
  instruction: string;
  family: string;
  qrCodeId: string[];
}

export interface EditDescriptorProps {
  descriptor: Descriptor;
  feedback?: {
    author: {
      id: string;
      name: string;
    };
    score: number;
    comments: string;
  }[];
  onCancel: () => void;
  onSave: (descriptor: Descriptor) => void;
}

const EditDescriptor = (p: EditDescriptorProps) => {
  const id = p.descriptor.id;
  const isNew = !id || id === "new";
  const [name, setName] = useState(p.descriptor.name);
  const [description, setDescription] = useState(p.descriptor.description);
  const [image, setImage] = useState(p.descriptor.image);
  const [color, setColor] = useState(p.descriptor.color);
  const [colorName, setColorName] = useState(p.descriptor.colorName);
  const [instruction, setInstruction] = useState(p.descriptor.instruction);
  const [family,setFamily] = useState(p.descriptor.family);
  const qrCodeId = p.descriptor.qrCodeId;

  const handleSaveClicked = () => {
    p.onSave({
      id,
      name,
      description,
      image,
      color,
      colorName,
      instruction,
      family,
      qrCodeId,
    });
  };

  return (
    <div css={styles.container}>
      <Card>
        <CardContent>
          <div css={styles.main}>
            <div>
              <Typography gutterBottom variant="h5" component="h2">
                {isNew ? "Create a Descriptor" : "Update Descriptor"}
              </Typography>
              <TextField
                fullWidth
                label="Descriptor name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                fullWidth
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={4}
              />
            </div>
            <div>
              <InputLabel>Image</InputLabel>
              <LoadableImage
                url={image}
                css={css`
                  height: 125px;
                `}
              />
              <ImageUpload onSuccess={setImage} />
            </div>
            <div>
              <InputLabel>Color</InputLabel>
              <DescriptorColor
                editing={true}
                color={color}
                colorName={colorName}
                onColorChange={setColor}
                onColorNameChange={setColorName}
              ></DescriptorColor>
              <TextField
                fullWidth
                label="Family"
                value={family}
                onChange={(e) => setFamily(e.target.value)}
              />
              <Box paddingTop={2}>
                <InputLabel>Evaluation Instruction</InputLabel>
                <TextField
                  fullWidth
                  // label="Description"
                  value={instruction}
                  onChange={(e) => setInstruction(e.target.value)}
                  multiline
                  rows={4}
                />

              </Box>
            </div>
            <div>
              <InputLabel>QR Code</InputLabel>
              <QRCode
                id={qrCodeId}
                fallbackText={isNew ? "Generated after creation" : undefined}
                css={css`
                  height: 175px;
                  width: 175px;
                `}
              />
            </div>
          </div>
        </CardContent>
      </Card>
      {isNew ? (
        <div />
      ) : p.feedback == null ? (
        <DescriptorFeedbackFallback />
      ) : (
        <DescriptorFeedback descriptorId={id} feedback={p.feedback} />
      )}
      <Footer>
        <div>
          {isNew ? null : (
            <Box component="span" m={1}>
              <DeleteDescriptor descriptorId={p.descriptor.id} />
            </Box>
          )}
          <Box component="span" m={1}>
            <Button variant="contained" onClick={p.onCancel}>
              Cancel
            </Button>
          </Box>
          <Box component="span">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveClicked}
            >
              {isNew ? "Create Descriptor" : "Save Changes"}
            </Button>
          </Box>
        </div>
      </Footer>
    </div>
  );
};

export default EditDescriptor;
