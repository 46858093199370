import React, { useState } from "react";
import { css } from "@emotion/react";

const cssParent = css`
  text-align: center;
  background-color: #000000;
`;

const cssImage = css`
  height: 100%;
  width: 100%;
  object-fit: contain;
  max-height: inherit;
`;

const cssErrorText = css`
  color: #ff5555;
  padding: 5px;
`;

const variantStyles = {
  default: null,
  icon: css`
    width: 125px;
    height: 75px;
  `,
};

interface LoadableImageProps {
  url?: string;
  variant?: "icon";
}

const LoadableImage = (p: LoadableImageProps) => {
  const [error, setError] = useState(false);

  const handleOnError = () => {
    setError(true);
  };

  return (
    <div css={[cssParent, variantStyles[p.variant || "default"]]} {...p}>
      {error ? (
        <span css={cssErrorText}>Error loading image</span>
      ) : p.url ? (
        <img alt="" css={cssImage} src={p.url} onError={handleOnError} />
      ) : (
        <span css={cssErrorText}>No image set</span>
      )}
    </div>
  );
};

export default LoadableImage;
