import React, { useState } from "react";
import { css } from "@emotion/react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Input,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import LoadableImage from "../components/LoadableImage";
import DescriptorColor from "../components/DescriptorColor";
import ImageUpload from "../components/ImageUpload";
import QRCode from "../components/QRCode";
import { ExportDescriptorCsvButton } from "./ExportDescriptorCsvButton";
import { FilterableTableHeader } from "../components/FilterableTableHeader";

const QUERY = gql`
  query GetAdminPanelDescriptors {
    descriptor(order_by: { name: asc }, where: { deleted: { _eq: false } }) {
      id
      name
      image
      color_name
      color_value
      description
      family
      evaluation_instruction
      qr_codes {
        id
      }
    }
  }
`;

const MUTATION = gql`
  mutation UpdateAdminPanelDescriptor(
    $id: uuid!
    $color_name: String
    $color_value: String
    $description: String!
    $image: String = ""
    $name: String!
    $family: String
    $evaluation_instruction: String!
  ) {
    update_descriptor_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        image: $image
        color_name: $color_name
        color_value: $color_value
        description: $description
        family: $family
        evaluation_instruction: $evaluation_instruction
      }
    ) {
      id
      name
      image
      color_name
      color_value
      description
      evaluation_instruction
      family
      qr_codes {
        id
      }
    }
  }
`;

const MUT_CREATE_NEW = gql`
  mutation CreateNewDescriptor(
    $name: String!
    $color_name: String = ""
    $color_value: String = ""
    $evaluation_instruction: String = ""
    $family: String = ""
  ) {
    insert_descriptor_one(
      object: {
        name: $name
        image: ""
        description: "A newly created descriptor"
        color_name: $color_name
        color_value: $color_value
        evaluation_instruction: $evaluation_instruction
        family: $family
        qr_codes: { data: {} }
      }
    ) {
      id
      name
      image
      description
      color_value
      color_name
      evaluation_instruction
      family
      qr_codes {
        id
      }
    }
  }
`;

interface QueryData {
  descriptor: [
    {
      id: string;
      name: string;
      image?: string;
      color_name?: string;
      color_value?: string;
      description?: string;
      evaluation_instruction: string;
      family: string;
      qr_codes: [{ id: string }];
    }
  ];
}

interface DescriptorRowViewProps {
  id: string;
  name: string;
  image?: string;
  colorValue?: string;
  colorName?: string;
  description?: string;
  evaluationInstruction: string;
  family: string;
  qrCodes: string[];
}

const DescriptorRowView = (p: DescriptorRowViewProps) => {
  const history = useHistory();

  return (
    <TableRow
      key={p.id}
      onDoubleClick={() => history.push(`/admin/descriptors/${p.id}`)}
    >
      <TableCell
        component="th"
        scope="row"
        css={css`
          min-width: 125px;
        `}
      >
        {p.name}
      </TableCell>
      <TableCell>
        <LoadableImage variant="icon" url={p.image} />
      </TableCell>
      <TableCell>
        <DescriptorColor
          editing={false}
          color={p.colorValue}
          colorName={p.colorName}
        ></DescriptorColor>
      </TableCell>
      <TableCell>{p.evaluationInstruction}</TableCell>
      <TableCell>{p.description}</TableCell>
      <TableCell>{p.family}</TableCell>
      <TableCell>
        <QRCode id={p.qrCodes} size={75} />
      </TableCell>
      <TableCell>
        <Button
          css={css`
            width: 100px;
          `}
          variant="contained"
          size="small"
          onClick={() => history.push(`/admin/descriptors/${p.id}`)}
        >
          Manage
        </Button>
      </TableCell>
    </TableRow>
  );
};

const DescriptorRowEdit = (p: DescriptorRowViewProps) => {
  const history = useHistory();
  const [name, setName] = useState(p.name);
  const [image, setImage] = useState(p.image);
  const [color, setColor] = useState(p.colorValue);
  const [colorName, setColorName] = useState(p.colorName);
  const [description, setDescription] = useState(p.description);
  const [family, setFamily] = useState(p.family);
  const [evaluationInstruction, setEvaluationInstruction] = useState(
    p.evaluationInstruction
  );
  const isNew = p.id === "new";

  const [saveExisting] = useMutation(MUTATION);
  const [createNew] = useMutation(MUT_CREATE_NEW, {
    update(cache, { data: { insert_descriptor_one } }) {
      cache.modify({
        fields: {
          descriptor(existingDescriptorRefs = []) {
            const newDescriptorRef = cache.writeFragment({
              data: insert_descriptor_one,
              fragment: gql`
                fragment NewDescriptor on descriptor {
                  id
                  name
                  image
                  description
                  family
                  color_value
                  color_name
                }
              `,
            });
            return [newDescriptorRef, ...existingDescriptorRefs];
          },
        },
      });
    },
  });

  const handleSaveClicked = () => {
    let baseVariables = {
      name: name,
      image: image,
      color_name: colorName,
      color_value: color,
      description: description,
      family: family,
      evaluation_instruction: evaluationInstruction,
    };

    const result = isNew
      ? createNew({ variables: baseVariables })
      : saveExisting({
          variables: { id: p.id, ...baseVariables },
        });

    result.then(() => {
      history.replace(`/admin/descriptors/`);
    });
  };

  return (
    <TableRow key={p.id}>
      <TableCell component="th" scope="row">
        <Input
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </TableCell>
      <TableCell>
        <div
          css={css`
            text-align: center;
          `}
        >
          <LoadableImage variant="icon" url={image} />
          <ImageUpload onSuccess={setImage} />
        </div>
      </TableCell>
      <TableCell>
        <DescriptorColor
          editing={true}
          color={p.colorValue}
          colorName={p.colorName}
          onColorChange={setColor}
          onColorNameChange={setColorName}
        ></DescriptorColor>
      </TableCell>
      <TableCell>
        <RadioGroup
          name="eval_instruction"
          value={evaluationInstruction}
          onChange={(e) => setEvaluationInstruction(e.target.value)}
        >
          <FormControlLabel value="sniff" control={<Radio />} label="Sniff" />
          <FormControlLabel value="taste" control={<Radio />} label="Taste" />
        </RadioGroup>
      </TableCell>
      <TableCell>
        <Input
          fullWidth
          value={family}
          onChange={(e) => setFamily(e.target.value)}
        />
      </TableCell>
      <TableCell>
        <Input
          fullWidth
          multiline
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </TableCell>
      <TableCell>
        <QRCode id={p.qrCodes} size={75} />
      </TableCell>
      <TableCell>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSaveClicked}
        >
          {isNew ? "Create" : "Save"}
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => history.replace(`/admin/descriptors/`)}
        >
          Cancel
        </Button>
      </TableCell>
    </TableRow>
  );
};

const styles = {
  main: css`
    height: calc(100vh - 160px);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 1em 1em;
    grid-template-areas: "table";
  `,
};

interface DescriptorListProps {
  editingId?: string;
}

const DescriptorList = (p: DescriptorListProps) => {
  const history = useHistory();
  const { data } = useQuery<QueryData>(QUERY, { pollInterval: 2000 });
  const isNew = p.editingId === "new";
  const [filterString, setFilterString] = useState("");
  const filteredDescriptorList = (data?.descriptor || []).filter((desc) =>
    desc.name.toLowerCase().includes(filterString.toLowerCase())
  );

  const handleCreateNewClicked = () => {
    history.replace("/admin/descriptors/new");
  };

  return (
    <div css={styles.main}>
      <TableContainer component={Paper}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <FilterableTableHeader
                label="Descriptor"
                value={filterString}
                onChange={setFilterString}
              />
              <TableCell>Image</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>Instruction</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Family</TableCell>
              <TableCell>QR Code</TableCell>
              <TableCell
                css={css`
                  min-width: 100px;
                `}
              >
                <Button
                  css={css`
                    width: 100px;
                  `}
                  disabled={isNew}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleCreateNewClicked}
                >
                  Create
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDescriptorList.map((desc) => {
              if (p.editingId === desc.id) {
                return (
                  <DescriptorRowEdit
                    id={desc.id}
                    name={desc.name}
                    image={desc.image}
                    colorName={desc.color_name}
                    colorValue={desc.color_value}
                    description={desc.description}
                    family={desc.family}
                    evaluationInstruction={desc.evaluation_instruction}
                    qrCodes={desc.qr_codes.map((d) => d.id)}
                  />
                );
              } else {
                return (
                  <DescriptorRowView
                    id={desc.id}
                    name={desc.name}
                    image={desc.image}
                    colorName={desc.color_name}
                    colorValue={desc.color_value}
                    description={desc.description}
                    family={desc.family}
                    evaluationInstruction={desc.evaluation_instruction}
                    qrCodes={desc.qr_codes.map((d) => d.id)}
                  />
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DescriptorList;
