import {
  FormControlLabel,
  Radio,
  RadioProps,
  RadioGroup,
  Typography,
  withStyles
} from "@material-ui/core";
import React from "react";
import QInputProps from "./QInputProps";

export default function QSelectRadio(p: QInputProps) {
  if (p.options == null || p.options.length === 0) {
    return <Typography>No options available.</Typography>;
  }

  const BlueRadio = withStyles({
    root: {
      color: "#4b94f2",
      '&$checked': {
        color: "#4b94f2",
      },
    },
    checked: {},
  })((props: RadioProps) => <Radio color="default" {...props} />);

  return (
    <div>
      <RadioGroup
        value={p.value || null}
        onChange={(e, v) => p.onValueChange(v)}
      >
        {p.options.map((option) => (
          <FormControlLabel key={option} value={option} control={<BlueRadio />} label={option} />
        ))}
      </RadioGroup>

    </div>
  );
}
