import React, { useState } from "react";
import { css } from "@emotion/react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@material-ui/core";
import { gql, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import UserActivatorDeactivator from "./UserActivatorDeactivator";
import { FilterableTableHeader } from "../components/FilterableTableHeader";

const QUERY = gql`
  query GetUsers {
    app_user(order_by: { disabled: asc, created_at: desc }) {
      id
      display_name
      is_admin
      secret
      disabled
      created_at
    }
  }
`;

interface QueryData {
  app_user: [
    {
      id: string;
      display_name: string;
      is_admin: boolean;
      secret: string;
      disabled: boolean;
      created_at: string;
    }
  ];
}

interface SecretHiderProps {
  secret: string;
}

const SecretHider = (p: SecretHiderProps) => {
  const [reveal, setReveal] = useState(false);

  const handleOnClick = () => {
    setReveal((current) => !current);
  };

  if (reveal) {
    return <span onDoubleClick={handleOnClick}>{p.secret}</span>;
  } else {
    return (
      <span
        onDoubleClick={handleOnClick}
        css={css`
          user-select: none;
          font-style: italic;
        `}
      >
        Double click to reveal
      </span>
    );
  }
};

const UserList = () => {
  const history = useHistory();
  const { data } = useQuery<QueryData>(QUERY);
  const [filterString, setFilterString] = useState("");
  const filteredUserList = (data?.app_user || []).filter((user) =>
    user.display_name.toLowerCase().includes(filterString.toLowerCase())
  );

  const handleCreateNewClicked = () => {
    history.push("/admin/users/new");
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <FilterableTableHeader
              label="Name"
              value={filterString}
              onChange={setFilterString}
            />
            <TableCell>Role</TableCell>
            <TableCell>Secret</TableCell>
            <TableCell
              css={css`
                width: 100px;
              `}
            >
              <Button
                fullWidth
                size="small"
                variant="contained"
                color="primary"
                onClick={handleCreateNewClicked}
              >
                Create
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredUserList.map((user) => {
            return (
              <TableRow
                key={user.id}
                css={css`
                  background-color: ${user.disabled ? "#B0B0B0" : "white"};
                `}
              >
                <TableCell component="th" scope="row">
                  {user.display_name}
                </TableCell>
                <TableCell>
                  {user.is_admin ? "Admin" : "Regular User"}
                </TableCell>
                <TableCell>
                  <SecretHider secret={user.secret} />
                </TableCell>
                <TableCell>
                  <UserActivatorDeactivator
                    mode={user.disabled ? "activate" : "deactivate"}
                    userName={user.display_name}
                    userId={user.id}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserList;
