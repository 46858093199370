import { css } from "@emotion/react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
} from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import ApolloRouter from "../../../util/ApolloRouter";
import { FilterableTableHeader } from "../components/FilterableTableHeader";
import { ListSurveysData, useListSurveysQuery } from "./queries/ListSurveys";

export interface SurveyListProps {}

function SurveyListReady(p: ListSurveysData) {
  const history = useHistory();
  const [filterString, setFilterString] = useState("");
  const filteredSurveys = (p.SUR_survey || []).filter((survey) =>
    survey.name.toLowerCase().includes(filterString.toLowerCase())
  );

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <FilterableTableHeader
              label="Survey"
              value={filterString}
              onChange={setFilterString}
            />
            <TableCell>Description</TableCell>
            <TableCell>Responses</TableCell>
            <TableCell
              css={css`
                min-width: 100px;
              `}
            >
              <Button
                css={css`
                  width: 100px;
                `}
                size="small"
                variant="contained"
                color="primary"
                onClick={() => history.push(`/admin/surveys/new`)}
              >
                Create
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredSurveys.map((survey) => (
            <TableRow
              key={survey.id}
              onDoubleClick={() => history.push(`/admin/surveys/${survey.id}`)}
            >
              <TableCell>{survey.internal_name}</TableCell>
              <TableCell>{survey.name}</TableCell>
              <TableCell>
                <Link to={`/admin/surveys/${survey.id}/responses`}>
                  {survey.responses_aggregate.aggregate.count}
                </Link>
              </TableCell>
              <TableCell>
                <Button
                  css={css`
                    width: 100px;
                  `}
                  variant="contained"
                  size="small"
                  onClick={() => history.push(`/admin/surveys/${survey.id}`)}
                >
                  Manage
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function SurveyList(p: SurveyListProps) {
  const query = useListSurveysQuery({ pollInterval: 5000 });
  return (
    <ApolloRouter
      loadable={query}
      ready={(data) => <SurveyListReady {...data} />}
    />
  );
}
