import React from "react";
import BottomTabs from "../../layout/BottomTabs";
import Layout from "../../layout/Layout";
import Navigator from "../../layout/Navigator";
import ProjectOverview from "../../project/ProjectInfo";
import useProjectName from "../../queries/ProjectName";
import useSampleDetails from "../../queries/SampleDetails";
import { useSession } from "../../../../SessionContext";
import SampleDetails from "../../sample/SampleDetails";
import useEvaluateSampleDescriptor from "../../queries/EvaluateSampleDescriptor";
import useEvaluateSample from "../../queries/EvaluateSample";

interface SampleDetailsPageProps {
  projectId: string;
  sampleId: string;
}

export default function SampleDetailsPage(p: SampleDetailsPageProps) {
  const { userId } = useSession();
  const { data: projectData } = useProjectName(p.projectId);
  const { data: sampleData } = useSampleDetails(p.sampleId, userId);

  const [updateEvaluation] = useEvaluateSample();
  const [setSampleDescriptorRating] = useEvaluateSampleDescriptor();

  const projectName = projectData?.project_by_pk?.name || "";
  const sampleName = sampleData?.sample_by_pk?.name || "";

  const userFeedback = sampleData?.sample_by_pk?.evaluations[0];
  const sample = sampleData?.sample_by_pk
    ? {
        id: sampleData.sample_by_pk.id,
        name: sampleData.sample_by_pk.name,
        description: sampleData.sample_by_pk.description,
        descriptors: sampleData.sample_by_pk.descriptors.map((desc) => {
          const rating = userFeedback?.descriptor_evaluations.find(
            (descEval) => descEval.descriptor_id === desc.id
          );
          return {
            id: desc.id,
            name: desc.name,
            rating: rating?.rating || 0,
          };
        }),
        likingScore: userFeedback?.liking_score,
        conceptFitScore: userFeedback?.concept_fit_score,
        comments: userFeedback?.comments,
      }
    : null;

  const handleDescriptorRatingChange = (
    descriptorId: string,
    newRating: number
  ) => {
    setSampleDescriptorRating({
      variables: {
        sample_id: p.sampleId,
        app_user_id: userId,
        descriptor_id: descriptorId,
        rating: newRating,
      },
      refetchQueries: ["SampleDetails"],
    });
  };

  const handleFeedbackSaved = (
    likingScore?: number,
    conceptFitScore?: number,
    comments?: string
  ) => {
    return updateEvaluation({
      variables: {
        sample_id: p.sampleId,
        app_user_id: userId,
        liking_score: likingScore,
        concept_fit_score: conceptFitScore,
        comments: comments,
      },
      refetchQueries: ["SampleDetails"],
    }) as any as Promise<void>;
  };

  return (
    <Layout
      nav={
        <Navigator
          parentLink={`/app/project/${p.projectId}/samples`}
          parent={projectName}
          current={sampleName}
        />
      }
      overview={<ProjectOverview projectId={p.projectId} />}
      footer={<BottomTabs projectId={p.projectId} />}
    >
      {sampleData?.sample_by_pk ? (
        <SampleDetails
          enableQuestionLiking={
            sampleData.sample_by_pk.session.enable_question_rate_sample
          }
          enableQuestionConceptFit={
            sampleData.sample_by_pk.session.enable_question_concept_fit
          }
          sample={sample!}
          onRatingChange={handleDescriptorRatingChange}
          onFeedbackSaved={handleFeedbackSaved}
        />
      ) : null}
    </Layout>
  );
}
