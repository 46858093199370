import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const query = gql`
mutation UpdateUserActivation($id: uuid!, $disabled: Boolean!) {
  update_app_user_by_pk(pk_columns: {id: $id}, _set: {disabled: $disabled}) {
    id
    disabled
    updated_at
  }
}
`;

export interface UpdateUserActivationData {
  update_app_user_by_pk: {
    id: string;
    disabled: boolean;
    updated_at: Date;
  };
}

export interface UpdateUserActivationVariables {
  id: string;
  disabled: boolean;
}

export function useUpdateUserActivation(
  options?: MutationHookOptions<UpdateUserActivationData, UpdateUserActivationVariables> | undefined
) {
  return useMutation<UpdateUserActivationData, UpdateUserActivationVariables>(
    query,
    options
  );
}
