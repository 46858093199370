import { MutationHookOptions, useMutation } from "@apollo/client";
import gql from "graphql-tag";

export const MUT = gql`
mutation AddSurveyAttachment($survey_id: uuid!, $label: String!, $url: String!) {
  insert_SUR_survey_attachment_one(object: {survey_id: $survey_id, label: $label, url: $url}) {
    id
  }
}
`;

export interface AddSurveyAttachmentData {
  insert_SUR_survey_attachment_one: {
    id: string;
  }
}

export interface AddSurveyAttachmentVariables {
  survey_id: string;
  label: string;
  url: string;
}

export function useAddSurveyAttachmentMutation(
  options?:
    | MutationHookOptions<AddSurveyAttachmentData, AddSurveyAttachmentVariables>
    | undefined
) {
  return useMutation<AddSurveyAttachmentData, AddSurveyAttachmentVariables>(
    MUT,
    options
  );
}
