import { gql, useLazyQuery } from "@apollo/client";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import triggerDownload from "js-file-download";
import React, { useState } from "react";

const query = gql`
  query GetDescriptorCsv($id: uuid!) {
    descriptor_by_pk(id: $id) {
      id
      name
      rating_csv {
        csv
      }
    }
  }
`;

function useCsvQuery() {
  return useLazyQuery<
    {
      descriptor_by_pk: {
        id: string;
        name: string;
        rating_csv: {
          csv: string;
        };
      } | null;
    },
    {
      id: string;
    }
  >(query);
}

export interface ExportDescriptorCsvButtonProperties {
  descriptorId: string;
}

export function ExportDescriptorCsvButton(
  p: ExportDescriptorCsvButtonProperties
) {
  const [busy, setBusy] = useState(false);
  const [query, a] = useCsvQuery();

  const handleClick = () => {
    setBusy(true);
    query({ variables: { id: p.descriptorId } }).then(result => {
      if (result.data?.descriptor_by_pk != null) {
        const csvData = result.data.descriptor_by_pk.rating_csv.csv;
        const name = result.data.descriptor_by_pk.name;
        triggerDownload(csvData, `desc_feedback_${name}.csv`, 'text/csv')
      } else {
        console.log('got no data when calling query for downloading descriptor csv')
      }
    });
    console.log("handle download descriptor csv");
  };

  return (
    <IconButton color="primary" onClick={handleClick}>
      <FontAwesomeIcon icon={faDownload} />
    </IconButton>
  );
}
